$primary: #E67E22;
$secondary: #009370;
$success: #009355;
$info: #007A93;
$warning: #d1731e;
$danger: #93001D;
$light: #F8D9B7;
$dark: #004A3D;
$background-light: #F5F5F5;
$surface-light: #FFFFFF;
$text-light: $dark;


$primary-dark: #FF9E4A;
$secondary-dark: #00BFA5;
$success-dark: #00BF7A;
$info-dark: #009EBF;
$warning-dark: #FF9E4A;
$danger-dark: #BF0030;
$light-dark: #262626;
$dark-dark: #1A1A1A;
$background-dark: #121212;
$surface-dark: #1E1E1E;
$text-dark: #FFFFFF;


// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

@import '~@fortawesome/fontawesome-free/css/fontawesome';
@import '~@fortawesome/fontawesome-free/css/regular';
@import '~@fortawesome/fontawesome-free/css/solid';
//@import '~@fortawesome/fontawesome-free/css/brands';

body {
    padding-top: 4.5rem;
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
    --bd-secondary-bg: #00BF7A;
    --bd-secondary-rgb: 0, 191, 122;

    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--bd-secondary-bg);
    --bs-btn-border-color: var(--bd-secondary-bg);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #018a59;
    --bs-btn-hover-border-color: #018a59;
    --bs-btn-focus-shadow-rgb: var(--bd-secondary-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #018a59;
    --bs-btn-active-border-color: #018a59;
}

.bd-mode-toggle {
    z-index: 1500;
}
